import React from 'react';
import imagePath from '../assets/Group 421.png';
import Box from '@mui/material/Box';

const ImageWithText = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '80px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '90%',
        maxWidth: '600px',
        textAlign: 'center',
      }}
    >
      <img
        src={imagePath}
        alt=""
        style={{
          width: '100%',
          height: 'auto',
        }}
      />
    </Box>
  );
};

export default ImageWithText;
