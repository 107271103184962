import React from "react";
import image from '../assets/bulb.png';
import image1 from '../assets/house.png';

const UpcomingProject2 = () => {
    return (
<div style={{ display: 'flex', justifyContent: 'center', }}>

        <div style={{ 
          position : 'absolute', top: '3110px',  width: '1350px',  display: 'flex', justifyContent: 'space-between',  }}>
            <div style={{
                padding:'0 0px 0px 40px'
            }}>

          <div style={{
            top: '2670px', // Adjusted top position
            left: '0px',
            width: '625px',
            height: '720px',
            background: 'transparent url("../assets/white.png") 0% 0% no-repeat padding-box',
            borderRadius: '0px 0px 10px 10px',
            opacity: '1',
            backgroundColor: '#F5F5F5',
            //color: '#757575'
            marginRight: '16px', // Add margin-right
           boxSizing:'border-box',
           float: 'left'
         
          }}>
            
            <p style={{
                position: 'absolute',
                top: '45px',
                left: '360px',
                transform: 'translateX(-50%)',
                width: '114px',
                height: '86px',
                fontSize: '65px',
                fontFamily: 'Neutra Text, Book',
                textAlign: 'left',
                letterSpacing: '0',
                lineHeight: '86px',
                margin: '0',
                textTransform: 'uppercase',
            color:'#313132'
            }}
            >Abs</p>
            <p style={{
                position: 'absolute',
                top: '120px',
                left: '360px',
                transform: 'translateX(-50%)',
                width: '229px',
                height: '21px',
                fontSize: '16px',
                fontFamily: 'Neutra Text, Book',
                textAlign: 'left',
                letterSpacing: '1.6px',
                lineHeight: '21px',
                margin: '0',
                textTransform: 'uppercase',
                 color:'#313132',
                 whiteSpace: 'nowrap'
                
            }}>
                Your Business Solution</p>
            <button
          style={{
          position: 'relative', // Change position to relative
          top:'-290px',
          left:'280px',
          width: '75px',
          height: '24px',
          fontSize: '20px',
          fontFamily: 'Montserrat, Regular',
          textAlign: 'center', // Change alignment to center
          letterSpacing: '0',
          lineHeight: '24px', // Adjust the line height to center the text vertically
          margin: '0',
          color: '#3BAAFF',
          border: 'none',
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }}>
                Explore&#62;
            </button>
            <img
            src={image}
            alt=""
            style={{
            position: 'relative',
            top: '250px',
            left: '20px',
            width: '480px',
            height: '460px',
            objectFit: 'cover',
          }}
            >
            </img>
            

          </div>
          </div>
          <div style={{
                padding:'0 50px 0px 0px'
            }}>
          <div style={{
            top: '2670px', // Adjusted top position
            left: '0px',
            width: '625px',
            height: '720px',
            background: 'transparent url("../assets/white.png") 0% 0% no-repeat padding-box',
            borderRadius: '0px 0px 10px 10px',
            opacity: '1',
            backgroundColor: '#F5F5F5',
            //color: '#757575'
            boxSizing:'border-box',
            float: 'Right',

            
 
          }}>
            <p style={{
                position: 'absolute',
                top: '28px',
                left: '1000px',
                transform: 'translateX(-50%)',
                width: '306x',
                height: '67x',
                fontSize: '55px',
                fontFamily: 'Montserrat, Regular',
                textAlign: 'left',
                letterSpacing: '0',
                lineHeight: '94px',
                margin: '0',
                textTransform: 'uppercase',
            color:'#313132'
            }}
            >Property</p>
            <p style={{
                position: 'absolute',
                top: '105px',
                left: '985px',
                transform: 'translateX(-50%)',
                width: '242px',
                height: '21px',
                fontSize: '16px',
                fontFamily: 'Neutra Text, Book',
                textAlign: 'left',
                letterSpacing: '1.6px',
                lineHeight: '21px',
                margin: '0',
                textTransform: 'uppercase',
                 color:'#313132',
                 whiteSpace: 'nowrap'
                
            }}>
                make secure your future
            </p>
            <button
          style={{
          position: 'relative', // Change position to relative
          top:'140px',
          left:'260px',
          width: '75px',
          height: '24px',
          fontSize: '20px',
          fontFamily: 'Montserrat, Regular',
          textAlign: 'center', // Change alignment to center
          letterSpacing: '0',
          lineHeight: '24px', // Adjust the line height to center the text vertically
          margin: '0',
          color: '#3BAAFF',
          border: 'none',
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }}>
                Explore&#62;
            </button>
            <img
            src={image1}
            alt=""
            style={{
            position: 'relative',
            top: '235px',
            left: '40px',
            width: '575px',
            height: '470px',
            objectFit: 'cover',
          }}
            >
            </img>
          </div>
          </div>
        </div>
      </div>

);
};

export default UpcomingProject2;
