import React from 'react';
import image from '../assets/Group 475.png';

const ProjectImage = () => {
  return (
    <div style={{ position: 'absolute', top: '924px', left: '50px', maxWidth: '100vw', maxHeight: '100vh'
     }}>
      <img
        src={image}
        alt=""
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
      />
    </div>
  );
};

export default ProjectImage;
