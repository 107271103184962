import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import logo from '../assets/Path 314.png';

const theme = createTheme({
  palette: {
    primary: {
      main: '#313132',
    },
  },
});

const Header = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position="static"
        sx={{
          top: '15px',
          left: '0px',
          width: '100%',
          height: '54px',
          opacity: 1,
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={logo}
            alt="Logo"
            sx={{
              width: '14px',
              height: '13px',
              marginRight: '10px',
              marginTop: '-15px',
            }}
          />

          <Button
            color="inherit"
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '12px',
              textAlign: 'left',
              letterSpacing: '0',
              lineHeight: '15px',
              padding: '0',
              color: '#F3F3F3',
              marginLeft: '80px',
              textTransform: 'none',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
            }}
          >
            Shop
          </Button>
          <Button
            color="inherit"
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '12px',
              textAlign: 'left',
              letterSpacing: '0',
              lineHeight: '15px',
              padding: '0',
              color: '#F3F3F3',
              marginLeft: '80px',
              textTransform: 'none',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
            }}
          >
            Products
          </Button>
          <Button
            color="inherit"
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '12px',
              textAlign: 'left',
              letterSpacing: '0',
              lineHeight: '15px',
              padding: '0',
              color: '#F3F3F3',
              marginLeft: '80px',
              textTransform: 'none',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
            }}
          >
            Services
          </Button>
          <Button
            color="inherit"
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '12px',
              textAlign: 'left',
              letterSpacing: '0',
              lineHeight: '15px',
              padding: '0',
              color: '#F3F3F3',
              marginLeft: '80px',
              textTransform: 'none',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
            }}
          >
            Contact
          </Button>
          <Button
            color="inherit"
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '12px',
              textAlign: 'left',
              letterSpacing: '0',
              lineHeight: '15px',
              padding: '0',
              color: '#F3F3F3',
              marginLeft: '80px',
              textTransform: 'none',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
            }}
          >
            Support
          </Button>

          <IconButton
            color="inherit"
            sx={{
              marginLeft: '80px',
              padding: '0',
              color: '#F3F3F3',
              fontSize: '12px',
              cursor: 'pointer',
            }}
          >
            <SearchIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
