import React from 'react';
import image from '../assets/drive-image.png';
import image1 from '../assets/raw.png';


const UpcomingProjects = () => {
  return (
    <div
      style={{
        position: 'relative',
        top: '1490px',
        left: '0px',
        width: '100%',
        height: '3227px',
        background: 'transparent url("../assets/Rectangle 1840.png") 0% 0% no-repeat padding-box',
        opacity: 1,
        backgroundColor: '#F1F1F1',
        color: '#313132',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >

        <p
        style={{
          position: 'absolute',
          top: '41px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '440px',
          height: '49px',
          fontSize: '40px',
          fontFamily: 'Montserrat, SemiBold',
          textAlign: 'center',
          letterSpacing: '0',
          lineHeight: '49px',
          margin: '0',
          textTransform: 'uppercase',
          whiteSpace: 'nowrap', // Added property to keep text in one line
        }}
      >
        Upcoming Projects
      </p>
      
      <div style={{ display: 'flex', justifyContent: 'center', }}>
        <div style={{ 
          position : 'absolute', top: '150px',  width: '1350px',  display: 'flex', justifyContent: 'space-between',  }}>
            <div style={{
                padding:'0 0px 0px 40px'
            }}>

          <div style={{
            top: '2670px', // Adjusted top position
            left: '0px',
            width: '625px',
            height: '720px',
            background: 'transparent url("../assets/image 1.png") 0% 0% no-repeat padding-box',
            borderRadius: '0px 0px 10px 10px',
            opacity: '1',
            backgroundColor: '#757575',
            //color: '#757575'
            marginRight: '16px', // Add margin-right
           boxSizing:'border-box',
           float: 'left'
         
          }}>
            
            <p style={{
                position: 'absolute',
                top: '45px',
                left: '360px',
                transform: 'translateX(-50%)',
                width: '208px',
                height: '79px',
                fontSize: '65px',
                fontFamily: 'Montserrat, Regular',
                textAlign: 'left',
                letterSpacing: '0',
                lineHeight: '79px',
                margin: '0',
                textTransform: 'uppercase',
            color:'#E2E2E2'
            }}
            >Drive</p>
            <p style={{
                position: 'absolute',
                top: '120px',
                left: '350px',
                transform: 'translateX(-50%)',
                width: '133px',
                height: '21px',
                fontSize: '16px',
                fontFamily: 'Neutra Text, Book',
                textAlign: 'left',
                letterSpacing: '1.6px',
                lineHeight: '21px',
                margin: '0',
                textTransform: 'uppercase',
                 color:'#E2E2E2',
                 whiteSpace: 'nowrap'
                
            }}>
                Secured Drive</p>
            <button
          style={{
          position: 'relative', // Change position to relative
          top:'145px',
          left:'270px',
          width: '75px',
          height: '24px',
          fontSize: '20px',
          fontFamily: 'Montserrat, Regular',
          textAlign: 'center', // Change alignment to center
          letterSpacing: '0',
          lineHeight: '24px', // Adjust the line height to center the text vertically
          margin: '0',
          color: '#3BAAFF',
          border: 'none',
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }}>
                Explore&#62;
            </button>
            <img
            src={image}
            alt=""
            style={{
            position: 'relative',
            top: '270px',
            left: '-35px',
            width: '650px',
            height: '410px',
            objectFit: 'cover',
          }}
            >
            </img>
            

          </div>
          </div>
          <div style={{
                padding:'0 50px 0px 0px'
            }}>
          <div style={{
            top: '2670px', // Adjusted top position
            left: '50px',
            width: '625px',
            height: '720px',
            background: 'transparent url("../assets/image 1.png") 0% 0% no-repeat padding-box',
            borderRadius: '0px 0px 10px 10px',
            opacity: '1',
            backgroundColor: '#757575',
            //color: '#757575'
            boxSizing:'border-box',
            float: 'Right',

            
 
          }}>
            <p style={{
                position: 'absolute',
                top: '28px',
                left: '1000px',
                transform: 'translateX(-50%)',
                width: '154x',
                height: '94x',
                fontSize: '70px',
                fontFamily: 'Segoe UI, Bold',
                textAlign: 'left',
                letterSpacing: '0',
                lineHeight: '94px',
                margin: '0',
            
            color:'#E2E2E2'
            }}
            >aPay</p>
            <p style={{
                position: 'absolute',
                top: '105px',
                left: '990px',
                transform: 'translateX(-50%)',
                width: '97px',
                height: '15px',
                fontSize: '12px',
                fontFamily: 'Montserrat, Regular',
                textAlign: 'left',
                letterSpacing: '1.2px',
                lineHeight: '15px',
                margin: '0',
                textTransform: 'uppercase',
                 color:'#E2E2E2',
                 whiteSpace: 'nowrap'
                
            }}>
                It's Secured
            </p>
            <button
          style={{
          position: 'relative', // Change position to relative
          top:'135px',
          left:'265px',
          width: '75px',
          height: '24px',
          fontSize: '20px',
          fontFamily: 'Montserrat, Regular',
          textAlign: 'center', // Change alignment to center
          letterSpacing: '0',
          lineHeight: '24px', // Adjust the line height to center the text vertically
          margin: '0',
          color: '#3BAAFF',
          border: 'none',
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }}>
                Explore&#62;
            </button>
            <img
            src={image1}
            alt=""
            style={{
            position: 'relative',
            top: '235px',
            left: '-40px',
            width: '700px',
            height: '460px',
            objectFit: 'cover',
          }}
            >
            </img>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingProjects;
