import React from 'react';
import image from '../assets/Mask Group 5.png';

const RectangleWithTextAndImage = () => {
  return (
    <div>
      <div
        style={{
          position: 'relative',
          top: '106px',
          left: '0px',
          width: '1920px',
          height: '680px',
          backgroundColor: '#FFFFFF',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '1920px',
          height: '691px',
        }}
      >
        <img
          src={image}
          alt=""
          style={{
            position: 'relative',
            top: '60px',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <p
          style={{
            position: 'absolute',
            top: '151px',
            left: '735px',
            width: '456px',
            height: '55px',
            fontSize: '45px',
            fontFamily: 'Montserrat, Medium',
            textAlign: 'left',
            letterSpacing: '0',
            lineHeight: '55px',
            margin: '0',
            color: '#313132',
            textTransform: 'uppercase',
            zIndex: '2',
          }}
        >
          Assistant Global
        </p>
        <p
          style={{
            position: 'absolute',
            top: '226px',
            left: '785px',
            width: '362px',
            height: '19px',
            fontSize: '16px',
            fontFamily: 'Montserrat, Regular',
            textAlign: 'left',
            letterSpacing: '15px',
            lineHeight: '19px',
            margin: '0',
            color: '#313132',
            textTransform: 'uppercase',
            zIndex: '2',
          }}
        >
          For The People
        </p>
      </div>
    </div>
  );
};

export default RectangleWithTextAndImage;
