import React from 'react';
import image1 from '../assets/facebook-rect.png';
import image2 from '../assets/linkedin-rect.png';
import image3 from '../assets/twitter.png';

const Footer = () => {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          top: '5500px',
          left: '0px',
          width: '1350px',
          height: '194px',
          backgroundColor: '#F5F5F7',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '5550px',
          left: '200px',
          width: '306px',
          height: '21px',
          textAlign: 'left',
          font: 'normal normal 200 16px/42px Poppins',
          letterSpacing: '0.32px',
          color: '#303030',
          opacity: 1,
        }}
      >
        More ways to trade: Call- 0008 876575 87
      </div>
      <div
        style={{
          position: 'absolute',
          top: '5600px',
          left: '200px',
          width: '157px',
          height: '24px',
          textAlign: 'left',
          font: 'normal normal normal 18px/24px Neutra Text',
          letterSpacing: '0px',
          color: '#313132',
          textTransform: 'uppercase',
          opacity: 1,
          whiteSpace: 'nowrap',
        }}
      >
        Assistant Global
      </div>
      <div
        style={{
          position: 'absolute',
          top: '5600px',
          left: '800px',
          width: '566px',
          height: '21px',
          textAlign: 'left',
          font: 'normal normal 200 16px/21px Poppins',
          letterSpacing: '0px',
          color: '#3D3D3D',
          opacity: 1,
        
        }}
      >
        Help     Send    Feedback    Privacy     Terms   Disclaimer
      </div>
      <div
        style={{
          position: 'absolute',
          top: '5590px',
          left: '200px',
          width: '920px',
          height: '2px',
          backgroundColor: '#707070',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '5560px',
          left: '1050px',
          display: 'flex',
          gap: '8px',
        }}
      >
        <a
          href="https://www.facebook.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={image1}
            alt="Facebook"
            style={{ width: '14.78px', height: '14.78px' }}
          />
        </a>
        <a
          href="https://www.linkedin.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={image2}
            alt="LinkedIn"
            style={{ width: '14.78px', height: '14.78px' }}
          />
        </a>
        <a
          href="https://twitter.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={image3}
            alt="Twitter"
            style={{ width: '14.78px', height: '14.78px' }}
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
