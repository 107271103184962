import React from "react";
import image from '../assets/Mask Group 4.png';
import image1 from '../assets/education.png';
import image2 from '../assets/Group 491.png';

const UpcomingProject4 = () => {
    return (
<div style={{ display: 'flex', justifyContent: 'center', }}>

        <div style={{ 
          position : 'absolute', top: '4585px',  width: '100%',  display: 'flex', justifyContent: 'space-between',  }}>
            <div style={{
                padding:'0 0px 0px 40px'
            }}>

          <div style={{
            top: '2670px', // Adjusted top position
            left: '50px',
            width: '625px',
            height: '720px',
            background: 'transparent url("../assets/suit.png") 0% 0% no-repeat padding-box',
            borderRadius: '0px 0px 10px 10px',
            opacity: '1',
            backgroundColor: '#E5E6E8',
            //color: '#757575'
            marginRight: '16px', // Add margin-right
           boxSizing:'border-box',
           float: 'left'
         
          }}>
             <img
            src={image2}
            alt=""
            style={{
            position: 'relative',
            top: '-450px',
            left: '250px',
            width: '145.517px',
            height: '39.313px',
            objectFit: 'cover',
          }}
            >
            </img>
            <p style={{
                position: 'absolute',
                top: '115px',
                left: '355px',
                transform: 'translateX(-50%)',
                width: '122px',
                height: '16px',
                fontSize: '12px',
                fontFamily: 'Neutra Text, Book',
                textAlign: 'left',
                letterSpacing: '1.2px',
                lineHeight: '16px',
                margin: '0',
                textTransform: 'uppercase',
                 color:'#393939',
                 whiteSpace: 'nowrap'
                
            }}>
                SENSE OF FASHION</p>
            <button
          style={{
          position: 'relative', // Change position to relative
          top:'-400px',
          left:'120px',
          width: '75px',
          height: '24px',
          fontSize: '20px',
          fontFamily: 'Montserrat, Regular',
          textAlign: 'center', // Change alignment to center
          letterSpacing: '0',
          lineHeight: '24px', // Adjust the line height to center the text vertically
          margin: '0',
          color: '#3BAAFF',
          border: 'none',
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }}>
                Explore&#62;
            </button>
            <img
            src={image}
            alt=""
            style={{
            position: 'relative',
            top: '165px',
            left: '-85px',
            width: '363px',
            height: '554px',
            objectFit: 'cover',
          }}
            >
            </img>
            

          </div>
          </div>
          <div style={{
                padding:'0 50px 0px 0px'
            }}>
          <div style={{
            top: '2670px', // Adjusted top position
            left: '50px',
            width: '625px',
            height: '720px',
            background: 'transparent url("../assets/edback.png") 0% 0% no-repeat padding-box',
            borderRadius: '0px 0px 10px 10px',
            opacity: '1',
            backgroundColor: '#F1F1F1',
            //color: '#757575'
            boxSizing:'border-box',
            float: 'Right',

            
 
          }}>
            <p style={{
                position: 'absolute',
                top: '50px',
                left: '1000px',
                transform: 'translateX(-50%)',
                width: '379x',
                height: '67x',
                fontSize: '55px',
                fontFamily: 'Montserrat, Regular',
                textAlign: 'left',
                letterSpacing: '0',
                lineHeight: '67px',
                margin: '0',
                textTransform: 'uppercase',
            color:'#313132'
            }}
            >Education</p>
            <p style={{
                position: 'absolute',
                top: '115px',
                left: '1005px',
                transform: 'translateX(-50%)',
                width: '178px',
                height: '19px',
                fontSize: '16px',
                fontFamily: 'Montserrat, Regular',
                textAlign: 'left',
                letterSpacing: '1.6px',
                lineHeight: '19px',
                margin: '0',
                textTransform: 'uppercase',
                 color:'#313132',
                 whiteSpace: 'nowrap'
                
            }}>
                Gain Knowledge 
            </p>
            <button
          style={{
          position: 'relative', // Change position to relative
          top:'135px',
          left:'270px',
          width: '75px',
          height: '24px',
          fontSize: '20px',
          fontFamily: 'Montserrat, Regular',
          textAlign: 'center', // Change alignment to center
          letterSpacing: '0',
          lineHeight: '24px', // Adjust the line height to center the text vertically
          margin: '0',
          color: '#3BAAFF',
          border: 'none',
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }}>
                Explore&#62;
            </button>
            <img
            src={image1}
            alt=""
            style={{
            position: 'relative',
            top: '50px',
            left: '20px',
            width: '632px',
            height: '632px',
            objectFit: 'cover',
          }}
            >
            </img>
          </div>
          </div>
        </div>
      </div>

);
};

export default UpcomingProject4;
