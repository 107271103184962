import React from "react";
import image from '../assets/gold.png';
import image1 from '../assets/vegis1.png';

const UpcomingProject3 = () => {
    return (
<div style={{ display: 'flex', justifyContent: 'center', }}>

        <div style={{ 
          position : 'absolute', top: '3850px',  width: '100%',  display: 'flex', justifyContent: 'space-between',  }}>
            <div style={{
                padding:'0 0px 0px 40px'
            }}>

          <div style={{
            top: '2670px', // Adjusted top position
            left: '50px',
            width: '625px',
            height: '720px',
            background: 'transparent url("../assets/image81.png") 0% 0% no-repeat padding-box',
            borderRadius: '0px 0px 10px 10px',
            opacity: '1',
            backgroundColor: '#2E2E2E',
            //color: '#757575'
            marginRight: '16px', // Add margin-right
           boxSizing:'border-box',
           float: 'left'
         
          }}>
            
            <p style={{
                position: 'absolute',
                top: '45px',
                left: '375px',
                transform: 'translateX(-50%)',
                width: '170px',
                height: '86px',
                fontSize: '65px',
                fontFamily: 'Neutra Text, Book',
                textAlign: 'left',
                letterSpacing: '0',
                lineHeight: '86px',
                margin: '0',
                textTransform: 'uppercase',
            color:'#FFFFFF'
            }}
            >Gold</p>
            <p style={{
                position: 'absolute',
                top: '120px',
                left: '380px',
                transform: 'translateX(-50%)',
                width: '238px',
                height: '19px',
                fontSize: '16px',
                fontFamily: 'Neutra Text, Book',
                textAlign: 'left',
                letterSpacing: '0.8px',
                lineHeight: '19px',
                margin: '0',
                textTransform: 'uppercase',
                 color:'#CBCBCB',
                 whiteSpace: 'nowrap'
                
            }}>
                accumulate your assest</p>
            <button
          style={{
          position: 'relative', // Change position to relative
          top:'-190px',
          left:'290px',
          width: '75px',
          height: '24px',
          fontSize: '20px',
          fontFamily: 'Montserrat, Regular',
          textAlign: 'center', // Change alignment to center
          letterSpacing: '0',
          lineHeight: '24px', // Adjust the line height to center the text vertically
          margin: '0',
          color: '#3BAAFF',
          border: 'none',
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }}>
                Explore&#62;
            </button>
            <img
            src={image}
            alt=""
            style={{
            position: 'relative',
            top: '335px',
            left: '10px',
            width: '500px',
            height: '355px',
            objectFit: 'cover',
          }}
            >
            </img>
            

          </div>
          </div>
          <div style={{
                padding:'0 50px 0px 0px'
            }}>
          <div style={{
            top: '2670px', // Adjusted top position
            left: '50px',
            width: '625px',
            height: '720px',
            background: 'transparent url("../assets/green.png") 0% 0% no-repeat padding-box',
            borderRadius: '0px 0px 10px 10px',
            opacity: '1',
            backgroundColor: '#DEEDD6',
            //color: '#757575'
            boxSizing:'border-box',
            float: 'Right',

            
 
          }}>
            <p style={{
                position: 'absolute',
                top: '35px',
                left: '1000px',
                transform: 'translateX(-50%)',
                width: '237x',
                height: '79x',
                fontSize: '65px',
                fontFamily: 'Montserrat, Regular',
                textAlign: 'left',
                letterSpacing: '0',
                lineHeight: '79px',
                margin: '0',
                textTransform: 'uppercase',
            color:'#313132'
            }}
            >Green</p>
            <p style={{
                position: 'absolute',
                top: '110px',
                left: '1005px',
                transform: 'translateX(-50%)',
                width: '189px',
                height: '19px',
                fontSize: '16px',
                fontFamily: 'Montserrat, Regular',
                textAlign: 'left',
                letterSpacing: '1.6px',
                lineHeight: '19px',
                margin: '0',
                textTransform: 'uppercase',
                 color:'#313132',
                 whiteSpace: 'nowrap'
                
            }}>
                Eat Healthy Food
            </p>
            <button
          style={{
          position: 'relative', // Change position to relative
          top:'-305px',
          left:'270px',
          width: '75px',
          height: '24px',
          fontSize: '20px',
          fontFamily: 'Montserrat, Regular',
          textAlign: 'center', // Change alignment to center
          letterSpacing: '0',
          lineHeight: '24px', // Adjust the line height to center the text vertically
          margin: '0',
          color: '#3BAAFF',
          border: 'none',
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }}>
                Explore&#62;
            </button>
            <img
            src={image1}
            alt=""
            style={{
            position: 'relative',
            top: '290px',
            left: '-10px',
            width: '540px',
            height: '460px',
            objectFit: 'cover',
          }}
            >
            </img>
          </div>
          </div>
        </div>
      </div>

);
};

export default UpcomingProject3;
