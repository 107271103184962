// App.js
import React from 'react';
import Header from './components/Header';
import ImageWithText from './components/ImageWithText';
import RectangleWithTextAndImage from './components/RectangleWithTextAndImage';
import RunningProjectText from './components/RunningProjectText';
import ProjectImage from './components/ProjectImage';
import ShowMoreButton from './components/ShowMoreButton';
import UpcomingProjects from './components/UpcomingProject';
import UpcomingProject2 from './components/UpcomingProject2';
import UpcomingProject3 from './components/UpcomingProject3';
import UpcomingProject4 from './components/UpcomingProject4';
import ShowMoreButton2 from './components/ShowMoreButton2';
import Footer from './components/Footer';

const App = () => {
  return (
    <div className="app">
      <Header />
      <ImageWithText/>
      <RectangleWithTextAndImage />
      <RunningProjectText/>
      <ProjectImage/>
      <ShowMoreButton/>
      <UpcomingProjects/>
      <UpcomingProject2/>
      <UpcomingProject3/>  
      <UpcomingProject4/>
      <ShowMoreButton2/>
      <Footer/>   
 
      
      {/* Content of your website goes here */}
      
   
    </div>
  );
}

export default App;
