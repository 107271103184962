import React from 'react';

const ShowMoreButton = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '2120px', // Adjust the top position as per your design
        left: '50%',
        transform: 'translateX(-50%)',
        width: '254px',
        height: '54px',
        backgroundColor: '#313132',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <button
        style={{
          position: 'relative', // Change position to relative
          width: '224px',
          height: '40px',
          fontSize: '20px',
          fontFamily: 'Montserrat, Regular',
          textAlign: 'center', // Change alignment to center
          letterSpacing: '0',
          lineHeight: '40px', // Adjust the line height to center the text vertically
          margin: '0',
          color: '#FFFFFF',
          border: 'none',
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }}
      >
        Show more
      </button>
    </div>
  );
};

export default ShowMoreButton;
